import { StudentSearchPage } from '@pages/student/search';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/app/student/courses/search')({
  component: StudentSearchPage,
  meta: () => [
    {
      title: 'Étudiant | Recherche de cours',
    },
  ],
});
