import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ListCoursesResponse } from '@buf/khiman_class-lib.bufbuild_es/proto/course/v1/course_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { CourseCard } from '@components/course-card/course-card.tsx';
import { useMe } from '@hooks/services/auth.tsx';
import { useInfiniteListCourses } from '@hooks/services/course.tsx';
import { useListLevels } from '@hooks/services/level.tsx';
import { useListSubjects } from '@hooks/services/subject.tsx';
import { InfiniteData } from '@tanstack/react-query';
import { Box, Button, Flex, Select, Skeleton, Stack, Text, TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

const ITEMS_PER_PAGE = 5;

export const StudentSearchPage = () => {
  const userId = useMe();
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [levelIdFilter, setLevelIdFilter] = useState<string | null>('');
  const [subjectIdFilter, setSubjectIdFilter] = useState<string | null>('');
  const [titleFilter, setTitleFilter] = useState<string | null>('');
  const [debouncedTitleFilter] = useDebouncedValue(titleFilter, 500);

  const now = useMemo(() => {
    const timeMS = Date.now();
    return Timestamp.fromDate(new Date(timeMS));
  }, []);

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteListCourses({
      teacherId: userId,
      after: now,
      pageSize: ITEMS_PER_PAGE,
      levelId: levelIdFilter || '',
      subjectId: subjectIdFilter || '',
      titleContains: debouncedTitleFilter || '',
    });

  const { data: levels } = useListLevels({
    // TODO: remove this hack and make the API return all levels?
    input: {
      pageSize: 1000,
      page: 1,
    },
  });

  const { data: subjects } = useListSubjects({
    // TODO: remove this hack and make the API return all levels?
    input: {
      pageSize: 1000,
      page: 1,
    },
  });

  const handleIntersect = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (!entry.isIntersecting) return;

      if (hasNextPage && !isFetching && !isFetchingNextPage) {
        console.log('Loading more past courses...');
        fetchNextPage();
      }
    },
    [hasNextPage, isFetching, isFetchingNextPage, fetchNextPage]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, {
      root: scrollContainerRef.current,
      rootMargin: '200px',
      threshold: 0,
    });

    const currentRef = loadMoreRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, [handleIntersect]);

  const handleManualLoadMore = () => {
    fetchNextPage();
  };

  const renderCourses = (courses: InfiniteData<ListCoursesResponse, unknown> | undefined) => {
    if (!courses) return null;

    return courses.pages.map((page, pageIndex) => (
      <React.Fragment key={pageIndex}>
        {page.courses.map((course) => (
          <CourseCard course={course} key={course.id} />
        ))}
      </React.Fragment>
    ));
  };

  return (
    <>
      <Flex w="100%" direction="row" gap="md">
        <Select
          label="Fitlrer par niveau"
          placeholder="Filtrer par niveau"
          clearable
          value={levelIdFilter}
          onChange={setLevelIdFilter}
          data={levels?.levels.map((level) => {
            return {
              value: level.id,
              label: level.name,
            };
          })}
          searchable
        />
        <Select
          label="Filtrer par matière"
          placeholder="Filtrer par matière"
          clearable
          value={subjectIdFilter}
          onChange={setSubjectIdFilter}
          data={subjects?.subjects.map((level) => {
            return {
              value: level.id,
              label: level.name,
            };
          })}
          searchable
        />
        <TextInput
          label="Filtrer par titre"
          placeholder="Filtrer par titre"
          value={titleFilter || ''}
          onChange={(e) => setTitleFilter(e.currentTarget.value)}
        />
      </Flex>
      <Box
        ref={scrollContainerRef}
        style={{
          height: 'calc(100vh - 200px)',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        <Skeleton visible={isLoading} height={120} mt="md">
          <Stack mt="md">
            {renderCourses(data)}
            {(hasNextPage || isFetchingNextPage) && (
              <div
                ref={loadMoreRef}
                style={{
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {isFetchingNextPage ? (
                  <Text size="sm" c="dimmed">
                    Chargement...
                  </Text>
                ) : (
                  <Button
                    variant="subtle"
                    onClick={handleManualLoadMore}
                    disabled={!hasNextPage || isFetching}
                  >
                    Charger plus
                  </Button>
                )}
              </div>
            )}
          </Stack>
        </Skeleton>
      </Box>
    </>
  );
};
